::-moz-selection {
  background-color: #39f;
}
::selection {
  background-color: #39f;
}
.bg-primary {
  background-color: #39f !important;
}
.bg-secondary {
  background-color: #1d84ea !important;
}
a {
  color: #39f;
}
a:hover,
a:focus {
  color: #0080ff;
}
.link-underline {
  box-shadow: 0 1px 0 0 #39f;
}
.text-primary {
  color: #39f !important;
}
.text-secondary {
  color: #1d84ea !important;
}
.alert-primary {
  background-color: #39f;
}
.banner-badge .banner-link:hover .banner-link-text,
.banner-badge .banner-link:focus .banner-link-text {
  background-color: #39f;
}
.header-3 .header-search-extended .btn,
.header-4 .header-search-extended .btn {
  background-color: transparent;
  color: #333;
}
.header-3 .header-search-extended .btn:hover,
.header-3 .header-search-extended .btn:focus,
.header-4 .header-search-extended .btn:hover,
.header-4 .header-search-extended .btn:focus {
  color: #39f;
}
.btn-video {
  color: #39f;
}
.btn-video:hover,
.btn-video:focus {
  background-color: #1d84ea;
}
.breadcrumb-item a:hover,
.breadcrumb-item a:focus {
  color: #39f;
}
.btn-link {
  color: #39f;
}
.btn-link:hover,
.btn-link:focus,
.btn-link .btn-link-dark:hover,
.btn-link .btn-link-dark:focus {
  color: #39f;
  border-color: #39f;
}
.btn-primary {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1d84ea;
  border-color: #1d84ea;
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
}
.btn-secondary {
  color: #fff;
  background-color: #1d84ea;
  border-color: #1d84ea;
  box-shadow: none;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #1d84ea;
  border-color: #1d84ea;
}
.btn-white {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.btn-white-2 {
  color: #333;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-white-2:hover,
.btn-white-2:focus,
.btn-white-2.focus,
.btn-white-2:not(:disabled):not(.disabled):active,
.btn-white-2:not(:disabled):not(.disabled).active,
.show > .btn-white-2.dropdown-toggle {
  color: #fff;
  background-color: #1d84ea;
  border-color: #1d84ea;
  box-shadow: none;
}
.btn-white-2.disabled,
.btn-white-2:disabled {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}
.btn-primary-white {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-primary-white:hover,
.btn-primary-white:focus,
.btn-primary-white.focus,
.btn-primary-white:not(:disabled):not(.disabled):active,
.btn-primary-white:not(:disabled):not(.disabled).active,
.show > .btn-primary-white.dropdown-toggle {
  color: #39f;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-primary-white.disabled,
.btn-primary-white:disabled {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
}
.btn-white-primary {
  color: #39f;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-white-primary:hover,
.btn-white-primary:focus,
.btn-white-primary.focus,
.btn-white-primary:not(:disabled):not(.disabled):active,
.btn-white-primary:not(:disabled):not(.disabled).active,
.show > .btn-white-primary.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-white-primary.disabled,
.btn-white-primary:disabled {
  color: #39f;
  background-color: #fff;
  border-color: #fff;
}
.btn-dark {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: none;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark.focus,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-outline {
  color: #39f;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline.focus,
.btn-outline:not(:disabled):not(.disabled):active,
.btn-outline:not(:disabled):not(.disabled).active,
.show > .btn-outline.dropdown-toggle {
  color: #39f;
  background-color: transparent;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.btn-outline.disabled,
.btn-outline:disabled {
  color: #39f;
  background-color: transparent;
}
.btn-outline-primary {
  color: #39f;
  background-color: transparent;
  background-image: none;
  border-color: #39f;
  box-shadow: none;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #39f;
  background-color: transparent;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #39f;
  background-color: transparent;
}
.btn-outline-primary-2 {
  color: #39f;
  background-color: transparent;
  background-image: none;
  border-color: #39f;
  box-shadow: none;
}
.btn-outline-primary-2:hover,
.btn-outline-primary-2:focus,
.btn-outline-primary-2.focus,
.btn-outline-primary-2:not(:disabled):not(.disabled):active,
.btn-outline-primary-2:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-2.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-outline-primary-2.disabled,
.btn-outline-primary-2:disabled {
  color: #39f;
  background-color: transparent;
}
.btn-outline-light {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #39f;
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-dark {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none;
}
.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #39f;
  background-color: transparent;
  border-color: #ebebeb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-dark-2 {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: none;
}
.btn-outline-dark-2:hover,
.btn-outline-dark-2:focus,
.btn-outline-dark-2.focus,
.btn-outline-dark-2:not(:disabled):not(.disabled):active,
.btn-outline-dark-2:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-2.dropdown-toggle {
  color: #39f;
  background-color: #fafafa;
  border-color: #ebebeb;
  box-shadow: none;
}
.btn-outline-dark-2.disabled,
.btn-outline-dark-2:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-dark-3 {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none;
}
.btn-outline-dark-3:hover,
.btn-outline-dark-3:focus,
.btn-outline-dark-3.focus,
.btn-outline-dark-3:not(:disabled):not(.disabled):active,
.btn-outline-dark-3:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark-3.dropdown-toggle {
  color: #39f;
  background-color: transparent;
  border-color: #39f;
  box-shadow: none;
}
.btn-outline-dark-3.disabled,
.btn-outline-dark-3:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-darker {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #d7d7d7;
  box-shadow: none;
}
.btn-outline-darker:hover,
.btn-outline-darker:focus,
.btn-outline-darker.focus,
.btn-outline-darker:not(:disabled):not(.disabled):active,
.btn-outline-darker:not(:disabled):not(.disabled).active,
.show > .btn-outline-darker.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-outline-darker.disabled,
.btn-outline-darker:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-gray {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #959595;
  box-shadow: none;
}
.btn-outline-gray:hover,
.btn-outline-gray:focus,
.btn-outline-gray.focus,
.btn-outline-gray:not(:disabled):not(.disabled):active,
.btn-outline-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-outline-gray.disabled,
.btn-outline-gray:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-lightgray {
  color: #333;
  background-color: transparent;
  background-image: none;
  border-color: #ebebeb;
  box-shadow: none;
}
.btn-outline-lightgray:hover,
.btn-outline-lightgray:focus,
.btn-outline-lightgray.focus,
.btn-outline-lightgray:not(:disabled):not(.disabled):active,
.btn-outline-lightgray:not(:disabled):not(.disabled).active,
.show > .btn-outline-lightgray.dropdown-toggle {
  color: #39f;
  background-color: #f5f6f9;
  border-color: #ebebeb;
  box-shadow: none;
}
.btn-outline-lightgray.disabled,
.btn-outline-lightgray:disabled {
  color: #333;
  background-color: transparent;
}
.btn-outline-white {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white.focus,
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #fff;
  background-color: #39f;
  border-color: #39f;
  box-shadow: none;
}
.btn-outline-white.disabled,
.btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white-2 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-2:hover,
.btn-outline-white-2:focus,
.btn-outline-white-2.focus,
.btn-outline-white-2:not(:disabled):not(.disabled):active,
.btn-outline-white-2:not(:disabled):not(.disabled).active,
.show > .btn-outline-white-2.dropdown-toggle {
  color: #fff;
  background-color: #1d84ea;
  border-color: #1d84ea;
  box-shadow: none;
}
.btn-outline-white-2.disabled,
.btn-outline-white-2:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white-4 {
  color: #fff;
  background-color: transparent;
  background-image: none;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-4:hover,
.btn-outline-white-4:focus,
.btn-outline-white-4.focus,
.btn-outline-white-4:not(:disabled):not(.disabled):active,
.btn-outline-white-4:not(:disabled):not(.disabled).active,
.show > .btn-outline-white-4.dropdown-toggle {
  color: #39f;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}
.btn-outline-white-4.disabled,
.btn-outline-white-4:disabled {
  color: #fff;
  background-color: transparent;
}
.newsletter-popup-container .input-group .btn:hover,
.newsletter-popup-container .input-group .btn:focus {
  background-color: #39f;
}
.bg-image .btn-link-dark:hover,
.bg-image .btn-link-dark:focus {
  color: #39f;
  border-color: #39f;
}
.bg-image .btn-outline-primary:hover,
.bg-image .btn-outline-primary:focus,
.bg-image .btn-outline-primary.focus,
.bg-image .btn-outline-primary:not(:disabled):not(.disabled):active,
.bg-image .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .bg-image .btn-outline-primary.dropdown-toggle {
  background-color: #39f;
  border-color: #39f;
}
.bg-image .btn-outline-dark:hover,
.bg-image .btn-outline-dark:focus,
.bg-image .btn-outline-dark.focus,
.bg-image .btn-outline-dark:not(:disabled):not(.disabled):active,
.bg-image .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .bg-image .btn-outline-dark.dropdown-toggle {
  color: #39f;
}
.card-title a {
  color: #39f;
}
.card-title a:before {
  color: #39f;
}
.card-title a.collapsed:hover,
.card-title a.collapsed:focus {
  color: #39f;
}
.count-wrapper {
  color: #39f;
}
.feature-box i {
  color: #39f;
}
.feature-box-simple i {
  color: #39f;
}
.form-control:focus {
  border-color: #39f;
}
.custom-control.custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::before {
  border-color: #39f;
}
.custom-control.custom-radio
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: #39f;
}
.icon-box-icon {
  color: #39f;
}
.icon-box-circle .icon-box-icon {
  background-color: #39f;
}
.instagram-feed-content a:hover,
.instagram-feed-content a:focus {
  color: #39f;
}
.close:hover,
.close:focus {
  color: #39f;
}
.page-header h1 span {
  color: #39f;
}
.page-link:hover,
.page-link:focus {
  color: #39f;
}
.page-item.active .page-link {
  color: #39f;
}
.social-icon:hover,
.social-icon:focus {
  color: #39f;
  border-color: #39f;
}
.testimonial-icon:before {
  color: #39f;
}
.nav.nav-tabs .nav-link:hover,
.nav.nav-tabs .nav-link:focus {
  color: #39f;
}
.nav.nav-tabs .nav-item.show .nav-link,
.nav.nav-tabs .nav-item .nav-link.active {
  color: #39f;
}
.nav.nav-pills .nav-link:hover,
.nav.nav-pills .nav-link:focus {
  color: #39f;
}
.nav.nav-pills .nav-item.show .nav-link,
.nav.nav-pills .nav-item .nav-link.active {
  color: #39f;
  border-bottom-color: #39f;
}
.nav.nav-border-anim .nav-link:before {
  background-color: #39f;
}
.title-link:hover,
.title-link:focus {
  box-shadow: 0 1px 0 0 #39f;
}
.product-countdown.countdown-primary .countdown-amount {
  color: #39f;
}
.product-title a:hover,
.product-title a:focus {
  color: #39f;
}
.product-price {
  color: #39f;
}
.product-label.label-primary {
  background-color: #39f;
}
.product-label.label-secondary {
  background-color: #1d84ea;
}
.product-label-text {
  color: #39f;
}
.ratings-primary .ratings-val {
  color: #39f;
}
.ratings-text a:hover,
.ratings-text a:focus {
  color: #39f;
}
.btn-product {
  color: #39f;
}
.btn-product:hover span,
.btn-product:focus span {
  color: #39f;
  box-shadow: 0 1px 0 0 #39f;
}
.btn-product-icon {
  color: #39f;
}
.btn-product-icon:hover,
.btn-product-icon:focus {
  background-color: #39f;
}
.product-body .btn-wishlist:hover,
.product-body .btn-wishlist:focus {
  color: #39f;
}
.btn-expandable span {
  background-color: #39f;
}
.product.product-4 .btn-product:hover,
.product.product-4 .btn-product:focus {
  background-color: #1d84ea;
}
.product.product-5 .btn-product {
  color: #39f;
}
.product.product-5 .btn-product:hover,
.product.product-5 .btn-product:focus {
  background-color: #39f;
}
.product.product-7 .btn-product {
  color: #39f;
}
.product.product-7 .btn-product span {
  color: #39f;
}
.product.product-7 .btn-product:hover,
.product.product-7 .btn-product:focus {
  background-color: #39f;
  border-bottom-color: #39f;
}
.product.product-8 .new-price {
  color: #39f;
}
.product.product-8 .btn-product:before {
  color: #39f;
}
.product.product-8 .btn-product:hover,
.product.product-8 .btn-product:focus {
  background-color: #39f;
}
.product.product-list .btn-product:hover,
.product.product-list .btn-product:focus {
  color: #39f;
}
.product.product-list .btn-product.btn-cart {
  color: #39f;
  border-bolor: #39f;
}
.product.product-list .btn-product.btn-cart:hover,
.product.product-list .btn-product.btn-cart:focus {
  background-color: #39f;
}
.footer a:hover,
.footer a:focus {
  color: #39f;
}
.footer-dark.footer-2 .widget-about-title {
  color: #39f;
}
.header-top a:hover,
.header-top a:focus {
  color: #39f;
}
.top-menu span {
  color: #39f;
}
.header-menu a:hover,
.header-menu a:focus {
  color: #39f;
}
.account a:hover,
.account a:focus {
  color: #39f;
}
.wishlist a:hover,
.wishlist a:focus {
  color: #39f;
}
.wishlist a .wishlist-count {
  background-color: #39f;
}
.cart-dropdown:hover .dropdown-toggle,
.cart-dropdown.show .dropdown-toggle,
.compare-dropdown:hover .dropdown-toggle,
.compare-dropdown.show .dropdown-toggle {
  color: #39f;
}
.compare-product-title a:hover,
.compare-product-title a:focus {
  color: #39f;
}
.compare-actions .action-link:hover,
.compare-actions .action-link:focus {
  color: #39f;
}
.cart-dropdown .cart-count {
  background-color: #39f;
}
.cart-dropdown .product-title a:hover,
.cart-dropdown .product-title a:focus {
  color: #39f;
}
.wishlist-link .wishlist-count {
  background-color: #39f;
}
.wishlist-link:hover,
.wishlist-link:focus {
  color: #39f;
}
.search-toggle:hover,
.search-toggle:focus,
.search-toggle.active {
  color: #39f;
}
.menu li:hover > a,
.menu li.show > a,
.menu li.active > a {
  color: #39f;
}
.demo-item a:hover,
.demo-item a:focus {
  color: #39f;
}
.tip {
  background-color: #39f;
}
.header-bottom .menu > li > a:before {
  background-color: #39f;
}
.category-dropdown .dropdown-toggle:before {
  background-color: #39f;
}
.category-dropdown .dropdown-toggle:hover,
.category-dropdown .dropdown-toggle:focus {
  color: #fff;
  background-color: #39f;
}
.category-dropdown:not(.is-on):hover .dropdown-toggle {
  background-color: #39f;
}
.category-dropdown.show .dropdown-toggle {
  color: #fff;
  background-color: #39f;
}
.category-dropdown .dropdown-item:hover,
.category-dropdown .dropdown-item:focus {
  color: #39f;
}
.menu-vertical li:hover > a,
.menu-vertical li.show > a,
.menu-vertical li.active > a {
  color: #39f;
}
.menu-vertical > li:hover > a,
.menu-vertical > li.show > a,
.menu-vertical > li.active > a {
  color: #39f;
}
.mobile-menu-close:hover,
.mobile-menu-close:focus {
  color: #39f;
}
.mobile-menu li a:hover,
.mobile-menu li a:focus {
  color: #39f;
}
.mobile-menu li.open > a,
.mobile-menu li.active > a {
  color: #39f;
}
.mmenu-btn:hover,
.mmenu-btn:focus {
  color: #39f;
}
.mobile-search .form-control:focus {
  border-color: #39f;
}
.nav.nav-pills-mobile .nav-link.active,
.nav.nav-pills-mobile .nav-link:hover,
.nav.nav-pills-mobile .nav-link:focus {
  color: #39f;
  border-bottom-color: #39f;
}
.mobile-cats-menu li a:hover,
.mobile-cats-menu li a:focus {
  color: #39f;
}
.mobile-menu-light .mobile-menu li.open > a,
.mobile-menu-light .mobile-menu li.active > a {
  color: #39f;
}
.mobile-menu-light .mobile-search .form-control:focus {
  border-color: #39f;
}
.header-4 .header-search .header-search-wrapper {
  border-color: #39f;
}
.header-4 .dropdown.category-dropdown .dropdown-toggle:not(:hover):not(:focus) {
  color: #39f;
}
@media screen and (max-width: 991px) {
  .header-4 .header-search-visible .header-search-wrapper:before {
    border-bottom-color: #39f;
  }
}
.header-6 .header-middle a:hover,
.header-6 .header-middle a:focus {
  color: #39f;
}
.header-8 .header-top {
  color: #d1e8ff;
  background-color: #39f;
}
.header-10 .header-search .header-search-wrapper {
  border-color: #39f;
}
.header-10 .category-dropdown .dropdown-toggle {
  background-color: #39f;
}
.header-10 .menu-vertical .menu-title {
  color: #39f;
}
.header-12 .dropdown.category-dropdown .dropdown-toggle {
  background-color: #39f;
}
.header-13 .dropdown.category-dropdown .dropdown-toggle {
  background-color: #39f;
}
.header-14 .header-search .header-search-wrapper {
  border-color: #39f;
}
.header-14 .dropdown.category-dropdown .dropdown-toggle {
  background-color: #39f;
}
.entry-video a:hover:after,
.entry-video a:focus:after {
  color: #39f;
}
.entry-meta a:hover,
.entry-meta a:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.entry-title a:hover,
.entry-title a:focus {
  color: #39f;
}
.entry-cats a:hover,
.entry-cats a:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.read-more:hover,
.read-more:focus {
  box-shadow: 0 1px 0 0 #39f;
}
.menu-cat a:hover,
.menu-cat a:focus {
  color: #39f;
}
.menu-cat li.active a {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.widget-search .btn:hover,
.widget-search .btn:focus {
  color: #39f;
}
.widget-cats a:hover,
.widget-cats a:focus {
  color: #39f;
}
.posts-list a:hover,
.posts-list a:focus {
  color: #39f;
}
.tagcloud a:hover,
.tagcloud a:focus {
  color: #39f;
}
.table .total-col {
  color: #39f;
}
.btn.btn-spinner:hover,
.btn.btn-spinner:focus {
  color: #39f;
}
.table.table-summary .summary-shipping-estimate a:hover,
.table.table-summary .summary-shipping-estimate a:focus {
  color: #39f;
  border-bottom-color: #39f;
}
.sidebar-toggler:hover,
.sidebar-toggler:focus {
  color: #39f;
}
#filter-price-range {
  color: #39f;
}
.checkout-discount label span {
  color: #39f;
}
.checkout-discount .form-control:focus {
  border-color: #39f;
}
.table.table-summary a:hover,
.table.table-summary a:focus {
  color: #39f;
}
.table.table-summary .summary-total td {
  color: #39f;
}
.accordion-summary .card-title a:before {
  border-color: #39f;
}
.accordion-summary .card-title a:after {
  background-color: #39f;
}
.paypal-link:hover,
.paypal-link:focus {
  color: #39f !important;
}
.coming-countdown .countdown-amount {
  color: #39f;
}
.coming-countdown.countdown-separator
  .countdown-section:not(:last-child):after {
  color: #39f;
}
.contact-box a:hover,
.contact-box a:focus {
  color: #39f;
}
.contact-list a:hover,
.contact-list a:focus {
  color: #39f;
}
.contact-list i {
  color: #39f;
}
.store a:not(.btn):hover,
.store a:not(.btn):focus {
  color: #39f;
}
.nav-dashboard .nav-link:hover,
.nav-dashboard .nav-link:focus,
.nav-dashboard .nav-link.active {
  color: #39f;
}
.form-tab .form-footer a:hover,
.form-tab .form-footer a:focus {
  color: #39f;
}
.nav-filter a:hover,
.nav-filter a:focus {
  color: #39f;
}
.nav-filter .active a {
  color: #39f;
  border-bottom-color: #39f;
}
.portfolio-title a:hover,
.portfolio-title a:focus {
  color: #39f;
}
.portfolio-tags a {
  color: #39f;
}
.portfolio-tags a:hover,
.portfolio-tags a:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.btn-product-gallery:hover,
.btn-product-gallery:focus {
  background-color: #39f;
}
.product-gallery-item:before {
  border-color: #39f;
}
.product-pager-link:hover,
.product-pager-link:focus {
  color: #39f;
}
.product-pager-link:hover span,
.product-pager-link:focus span {
  box-shadow: 0 1px 0 #39f;
}
.product-details .product-cat a:hover,
.product-details .product-cat a:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.product-details .product-size a.active,
.product-details .product-size a:hover,
.product-details .product-size a:focus {
  color: #39f;
  border-color: #39f;
}
.size-guide:hover,
.size-guide:focus {
  color: #39f;
}
.product-details-action .btn-cart {
  color: #39f;
  border-color: #39f;
}
.product-details-action .btn-cart:hover,
.product-details-action .btn-cart:focus {
  border-color: #39f;
  background-color: #39f;
}
.product-details-tab .nav.nav-pills .nav-link:hover,
.product-details-tab .nav.nav-pills .nav-link:focus {
  color: #39f;
  border-bottom-color: #39f;
}
.product-desc-content a:hover,
.product-desc-content a:focus {
  color: #39f;
  border-bottom-color: #39f;
}
.review h4 a:hover,
.review h4 a:focus {
  color: #39f;
}
.review-action a:hover,
.review-action a:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.product-details-extended .nav.nav-pills .nav-link.active,
.product-details-extended .nav.nav-pills .nav-link:hover,
.product-details-extended .nav.nav-pills .nav-link:focus {
  border-color: #39f;
}
.editor-content a:hover,
.editor-content a:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.editor-content blockquote {
  border-left-color: #39f;
}
.entry-tags a:hover,
.entry-tags a:focus {
  color: #39f;
}
.entry-author-details h4 a:hover,
.entry-author-details h4 a:focus {
  color: #39f;
}
.author-link:hover,
.author-link:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.pager-link {
  color: #39f;
}
.pager-link:hover,
.pager-link:focus {
  color: #39f;
}
.pager-link:hover:after,
.pager-link:focus:after {
  color: #39f;
}
.comment-reply:hover,
.comment-reply:focus {
  color: #39f;
  box-shadow: 0 1px 0 #39f;
}
.comment-user h4 a:hover,
.comment-user h4 a:focus {
  color: #39f;
}
.product-col .product-title a:hover,
.product-col .product-title a:focus {
  color: #39f;
}

.quickView-content .details-action-wrapper .btn-product:hover span,
.quickView-content .details-action-wrapper .btn-product:focus span {
  color: #39f;
  box-shadow: 0 1px 0 0 #39f;
}
.quickView-content .product-details-action .btn-cart {
  transition: color 0.3s;
}
.quickView-content .product-details-action .btn-cart:hover,
.quickView-content .product-details-action .btn-cart:focus {
  border-color: #39f;
  background-color: #39f;
  color: #fff;
}
.quickView-content .btn-wishlist,
.quickView-content .btn-compare {
  border: none;
}
.quickView-content .btn-wishlist:before,
.quickView-content .btn-compare:before {
  color: #39f;
}
.quickView-content .btn-wishlist:hover,
.quickView-content .btn-wishlist:focus,
.quickView-content .btn-compare:hover,
.quickView-content .btn-compare:focus {
  color: #39f;
  background-color: transparent;
}
.quickView-content .btn-wishlist:hover span,
.quickView-content .btn-wishlist:focus span,
.quickView-content .btn-compare:hover span,
.quickView-content .btn-compare:focus span {
  color: #39f;
}
.quickView-content .btn-fullscreen:hover,
.quickView-content .btn-fullscreen:focus {
  color: #fff;
  background-color: #39f;
}
.quickView-content .product-left .carousel-dot.active img {
  opacity: 1;
  box-shadow: 0 0 0 1px #39f;
}
.quickView-content .product-left .carousel-dot:hover img,
.quickView-content .product-left .carousel-dot:focus img {
  opacity: 1;
}
.newsletter-popup-container .banner-title span {
  color: #39f;
}
/*# sourceMappingURL=skin-demo-4.css.map */
