@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800");
.text-third {
  color: #ef837b;
}
.bg-light {
  background-color: #f4f7ff !important;
}
.title-desc {
  font-size: 1.6rem;
  line-height: 1;
  margin-top: 0.3rem;
}
.intro-slider-container,
.intro-slide {
  height: 310px;
  background-color: #f5f6f9;
}
.intro-slide {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
}
.slide-one {
  background-image: url(../images/slide-1.jpg);
  position: relative;
}
.slide-two {
  background-image: url(../images/hommer-banner-second-slide.jpeg);
  position: relative;
}
.slide-one::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.7); */
}
.banner-laptops-title {
  font-size: 57px !important;
}

.slide-two::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.intro-slide .intro-content {
  position: static;
  left: auto;
  top: auto;
  transform: translateY(0);
  -ms-transform: translateY(0);
}
.intro-subtitle {
  margin-bottom: 1.5rem;
}
.intro-title {
  color: #333;
  letter-spacing: -0.03em;
  font-size: 3.4rem;
  margin-bottom: 0;
}

.canceled-price {
  text-decoration: line-through !important;
  color: #777 !important;
}
.intro-price {
  color: #777;
  font-weight: 700;
  line-height: 1.2;
  font-size: 3.4rem;
  letter-spacing: -0.025em;
  margin-top: 1rem;
  margin-bottom: 1.2rem;
}
.intro-price sup {
  top: -0.8em;
  font-weight: 300;
  font-size: 1.4rem;
  letter-spacing: -0.03em;
}
.intro-old-price {
  position: relative;
}
.intro-old-price:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #999;
}

.cat-blocks-container {
  padding-top: 0.5rem;
}
.cat-blocks-container [class*="col-"] {
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.cat-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
  text-decoration: none !important;
}
.cat-block figure {
  display: inline-flex;
  align-items: center;
  position: relative;
  flex: 1;
  margin: 0;
}
.cat-block figure span {
  position: relative;
}
.cat-block figure span:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.2rem;
  left: 45%;
  width: 105%;
  margin-left: -45%;
  height: 0.3rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.35s ease;
  filter: blur(3px);
  opacity: 0.5;
}
.cat-block img {
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.35s ease;
}
.cat-block:hover img {
  transform: translateY(-14px);
}
.cat-block:hover figure span:after {
  opacity: 1;
}
.cat-block-title {
  color: #333;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  margin-top: 2rem;
  margin-bottom: 0;
}
.banner-content {
  left: 30px;
  padding-top: 0;
}
.banner-subtitle {
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  margin-bottom: 1.3rem;
}
.banner-title {
  color: #999;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.25;
  letter-spacing: -0.01em;
  margin-bottom: 1.2rem;
}
.main .banner-title strong {
  font-weight: 600;
  color: #333;
}
@media (min-width: 576px) {
  .ml-15 {
    margin-left: 25rem;
  }
}

.banner-link {
  display: inline-block;
  font-weight: 400;
  line-height: 1.3;
  padding: 0.3rem 1rem;
  border-radius: 20px;
  letter-spacing: -0.01em;
  margin-left: -1rem;
  text-align: center;
  min-width: 110px;
}
.banner-link i {
  margin-left: 0.7rem;
}
.banner-link:hover,
.banner-link:focus {
  color: #fff;
  background-color: #39f;
  text-decoration: none !important;
}
.nav.nav-pills .nav-link {
  font-size: 1.4rem;
}
.nav.nav-pills .nav-item + .nav-item {
  margin-left: 0.5rem;
}
.heading .title {
  font-weight: 600;
  letter-spacing: -0.025em;
}
.product.product-2 {
  background-color: #fff;
  border: 0.1rem solid #ebebeb;
  margin-bottom: 2rem;
}
.product.product-2 .product-nav-dots {
  margin-bottom: 1.5rem;
}
.product-cat {
  color: #ccc;
}
.product-body {
  overflow: hidden;
}
.btn-product + .btn-product {
  border-left-style: solid;
}
.product-action:not(.product-action-dark) {
  background-color: #f6f7fa;
}
.product-action:not(.product-action-dark) .btn-product + .btn-product {
  border-left-color: #d1d1d3;
}
.product-action:not(.product-action-dark) .btn-product:not(:hover):not(:focus) {
  color: #333;
}
.product-action-dark .btn-product:not(:hover):not(:focus) {
  color: #fff;
}
.new-arrivals .product-price,
.trending-products .product-price {
  margin-bottom: 1.2rem;
  color: #65b650;
}
.for-you .product.product-2 {
  border: none;
}
.trending-products .product-body {
  padding: 1.6rem 0.4rem 1.6rem 2rem;
}
.ratings-container {
  margin-bottom: 1.5rem;
}
.old-price {
  text-decoration: none;
}
.btn-product-icon {
  color: #39f;
  border-color: #39f;
  background-color: transparent;
  border: 0.1rem solid #39f;
}
.btn-product-icon span {
  background-color: #39f;
  top: -1px;
  bottom: -1px;
}
.btn-product-icon:hover,
.btn-product-icon:focus {
  color: #39f;
  border-color: #39f;
  background-color: transparent;
}
.btn-wishlist:hover:before,
.btn-wishlist:focus:before {
  content: "\f233";
}
.btn-more {
  text-transform: capitalize;
}
.deal-col {
  display: flex;
  align-items: stretch;
}
.deal {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 3.6rem 4rem 4.8rem;
  background-color: #f5f6f9;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 2rem;
  min-height: 420px;
}
.deal > div {
  width: 100%;
}
.deal .btn {
  padding: 0 0 0.2rem;
}
.deal h4 {
  color: #777;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.25;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
  max-width: 240px;
}
.deal h2 {
  color: #ef837b;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: -0.01em;
  margin-bottom: 0.2rem;
  max-width: 240px;
}
.deal .product-title {
  max-width: 240px;
  margin-bottom: 0.7rem;
}
.deal .product-price {
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 0.4rem;
}
.deal-content {
  align-self: center;
  margin-top: -2rem;
}
.deal-bottom {
  align-self: flex-end;
}
.btn.btn-outline-dark-2 {
  border-color: #d7d7d7;
}
.deal-countdown {
  margin-bottom: 0;
  margin-left: -0.8rem;
  max-width: 180px;
}
.deal-countdown.offer-countdown {
  max-width: 240px;
}
.deal-countdown .countdown-show3 .countdown-section {
  width: calc(33.33% - 16px);
}
.deal-countdown .countdown-show4 .countdown-section {
  width: calc(25% - 16px);
}
.deal-countdown .countdown-section {
  position: relative;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  padding: 0.9rem 0 0.3rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  background-color: #39f;
  border-radius: 0.3rem;
  border: none;
  margin-bottom: 2rem;
}
.deal-countdown .countdown-section:not(:last-child):after {
  color: #333333;
  content: ":";
  display: inline-block;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  position: absolute;
  left: 100%;
  margin-left: 6px;
  margin-top: -1px;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.deal-countdown .countdown-period {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -2rem;
  display: block;
  font-weight: 400;
  color: #666666;
  text-transform: lowercase;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deal-countdown .countdown-amount {
  display: inline-block;
  color: #fff;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: -0.03em;
  margin-bottom: 0.4rem;
}
.new-price {
  font-weight: 400;
  letter-spacing: -0.01em;
}
.btn-link {
  letter-spacing: -0.01em;
}
.btn-link i:last-child {
  margin-left: 0.5rem;
}
.cta-border {
  position: relative;
  border: none;
  background-color: #666666;
  background-size: cover;
  font-size: 2rem;
  margin-left: 70px;
  padding-top: 4.9rem;
  padding-bottom: 4.9rem;
}
.cta-border .cta-img {
  position: absolute;
  left: -70px;
  top: -5px;
  height: 175px;
  width: 250px;
}
.cta-border .cta-text {
  margin-right: 0;
}
.cta-border .cta-text p {
  font-size: 2.4rem;
  line-height: 1.3;
  letter-spacing: -0.01em;
  color: #fff;
}
.cta-border .cta-text::before {
  display: none;
}
.cta-border p {
  max-width: none;
}
.cta-border p strong {
  font-weight: 600;
}
.cta-border .btn:not(.btn-block) {
  min-width: 230px;
}
.cta-border .btn {
  margin-right: 70px;
  margin-left: 70px;
}
.cta-border .btn i:last-child {
  margin-left: 2.5rem;
}
.cta-heading {
  margin-bottom: 2.2rem;
}
.cta-heading .cta-title {
  margin-bottom: 0.2rem;
}
.cta-title {
  font-weight: 600;
  font-size: 2.4rem;
  letter-spacing: -0.025em;
}
.cta-desc {
  font-size: 1.6rem;
}
.cta .btn:not(.btn-block) {
  min-width: 178px;
}
.icon-boxes-container {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.icon-box-side .icon-box-title {
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.icon-box-side p {
  font-size: 1.6rem;
  letter-spacing: -0.01em;
}
.icon-box-side .icon-box-icon {
  font-size: 4rem;
}
.footer {
  background-color: #f5f6f9;
}
.footer .footer-middle {
  border-top: none;
  padding: 5rem 0 3.5rem;
}
.footer .widget-title {
  font-weight: 600;
}
.widget-list a {
  position: relative;
  color: inherit;
  text-decoration: none;
}
.widget-list a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.1rem;
  background-color: #39f;
  transform-origin: right center;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}
/* .widget-list a:hover:before,
.widget-list a:focus:before {
  transform-origin: left center;
  transform: scale(1, 1);
} */
.widget-about p:last-of-type {
  margin-bottom: 1.2rem;
}
.widget-call {
  position: relative;
  font-size: 1.3rem;
  padding: 2.1rem 2rem 1.6rem 7rem;
  border: 0.1rem solid #e1e1e1;
  border-radius: 0.3rem;
  line-height: 1.25;
  max-width: 270px;
}
.widget-call i {
  display: inline-block;
  color: #333;
  font-size: 3rem;
  line-height: 1;
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}
.widget-call a {
  display: block;
  font-weight: 400;
  font-size: 2rem;
  color: #39f;
}
.widget-call a:hover,
.widget-call a:focus {
  color: #1a8cff;
  box-shadow: none;
}
.footer-bottom .container {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
/* custom css  */
.top-social {
  display: flex;
  gap: 20px;
  margin: 0;
}
.product-banner {
  background-image: url(../images/page-header-bg.jpg);
}
@media screen and (min-width: 576px) {
  .intro-slider-container,
  .intro-slide {
    height: 360px;
  }
}
@media screen and (min-width: 768px) {
  .intro-title {
    font-size: 4rem;
  }
  .intro-price {
    font-size: 4rem;
  }
  .intro-price sup {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 992px) {
  .intro-slider-container,
  .intro-slide {
    height: 400px;
  }
  .intro-price {
    font-size: 4.4rem;
  }
  .intro-price sup {
    font-size: 1.8rem;
  }
  .intro-title {
    font-size: 4.4rem;
  }
}
@media screen and (min-width: 1200px) {
  .intro-content .btn {
    min-width: 170px;
  }
  .intro-title {
    font-size: 5rem;
  }
  .intro-price {
    font-size: 5rem;
    margin-bottom: 1.7rem;
  }
  .intro-price sup {
    top: -1.1em;
    font-size: 2rem;
  }

  .btn-more {
    min-width: 270px;
  }
}
@media screen and (max-width: 991px) {
  .heading {
    text-align: center;
  }
  .heading .title {
    margin-bottom: 1rem;
  }
}

.justify-text-p {
  text-align: justify;
}

.about-main-title {
  font-size: 4.3rem;
  font-weight: 700;
}
